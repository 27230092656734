import React, {useState, useEffect, useContext} from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../UI/Form/input'
import { gsap } from 'gsap'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../../firebase'
import { navigate } from 'gatsby'
import { FaBorderNone } from 'react-icons/fa';
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Veuillez entrer votre mot de passe')
})

const NewPassword = ({code, email}) => {

  const [sent, setSent] = useState(false)
  // const Firebase = useContext(FirebaseContext)
  // const { setAuthUser } = useContext(AuthContext)
  const firebase = useContext(FirebaseContext)


  function resetPasswd(password) {
    firebase.auth.confirmPasswordReset(code, password).then(function(resp) {
      setSent(!sent)
    })
  }

  useEffect(() => {
    // gsap.to(".sign-head", {opacity: 1, duration: .5, y: 20, ease: "back", delay: .4})
    // gsap.to(formRef.children, {opacity: 1, duration: .5, y: 20, ease: "back", stagger: .1})
    // sent && gsap.from(".sign-success", {opacity: 0, display: "none", ease: "back", duration: .3, y: 20, delay: .2 })
    // sent && gsap.to(".sign-reset", {opacity: 0, y: -20, duration: .2, ease: "back"})
  },)

  let formRef = null

  const handlePatient = () => {
    navigate('/signin')
  }
  const handleDoctor = () => {
    navigate('/doctor/')
  }

  return (
    <div className="sign center">
      <div className="sign-reset">
        {/* <h4 className="sign-head color-0">Changez Mot de Passe</h4> */}
        <h3 className="center color-4">Changez Mot de Passe</h3>
        {
          email
          ?
          <>
            <h5 className="center color-0">pour le compte: {email}</h5>
            {
                sent
                ?
                  <div className="sign-success">
                    <p className="color-1">Votre mot de passe a &eacute;t&eacute; change avec succ&egrave;ss. Veuillez cliquer sur le bouton ci-dessous correspondant &agrave; votre profile pour vous connecter.</p>
                    <div>
                      <Row>
                        <Col l={6} s={12}>
                          <div className="center">
                            <p className="verified-cta color-0">Patient<span role="img" aria-labelledby="point_down">👇</span></p>
                            <button onClick={handlePatient} className="mybtn color-0-bg color-3">Se Connecter</button>
                          </div>
                        </Col>
                        <Col l={6} s={12}>
                          <div className="center">
                            <p className="verified-cta color-0">M&eacute;decin ou autre professionel de sant&eacute;<span role="img" aria-labelledby="point_down">👇</span></p>
                            <button onClick={handleDoctor} className="mybtn color-4-bg color-3">Se Connecter</button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                :
                <>
              <p className="center color-1">Entrez votre nouveau mot de passe</p>
              <Formik
                initialValues={{
                  password: ''
                }}
                validationSchema={LoginSchema}
                onSubmit={(values, {setSubmitting}) =>(
                  resetPasswd(values.password)
                )}
              >
                {({isSubmitting, isValid, handleReset}) => (
                  <Form className="styled-form" ref={ el => formRef = el}>
                    <Field
                      type='password'
                      name='password'
                      placeholder='Votre password ...'
                      component={Input}
                    />
                    <button className="styled-button" type='submit'>Envoyer</button>
                    
                  </Form>           
                )}
              </Formik>
              </>
            }
          </>
          :
          <p> Le code de changement d'email a d&eacute;j&agrave; &eacute;t&eacute; utilis&eacute;, veuillez recommencer</p>
      }
      </div>

   
    </div>
  );
};

export default NewPassword;