import React from 'react';
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'

const EmailVerification = ({verified}) => {

  const handlePatient = () => {
    navigate('/signin')
  }
  const handleDoctor = () => {
    navigate('/doctor/')
  }
  return (
    <>
      {
        verified
        ?
          <>
            <h3 className="center color-4">
              Compte v&eacute;rifi&eacute; avec succ&egrave;s 
            </h3>
            <p className="color-0 app-home-action-cta">
              Votre email a été vérifiée  avec succès. Veuillez cliquer sur le bouton ci-dessous correspondant &agrave; votre profile pour vous connecter.
            </p>
            <div>
              <Row>
                <Col l={6} s={12}>
                  <div className="center">
                    <p className="verified-cta color-0">Patient<span role="img" aria-labelledby="point_down">👇</span></p>
                    <button onClick={handlePatient} className="mybtn color-0-bg color-3">Se Connecter</button>
                  </div>
                </Col>
                <Col l={6} s={12}>
                  <div className="center">
                    <p className="verified-cta color-0">M&eacute;decin ou autre professionel de sant&eacute;<span role="img" aria-labelledby="point_down">👇</span></p>
                    <button onClick={handleDoctor} className="mybtn color-4-bg color-3">Se Connecter</button>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        :
          <>
            <h3 className="center color-4">
              &Eacute;ch&egrave;c de la v&eacute;rification
            </h3>
            <p className="sign-alt center color-1">
              Votre code de v&eacute;rificatition n'est pas valide ou a expir&eacute;. Veuillez essayer de vous connecter en cliquant sur le bouton ci-dessous correspondant &agrave; votre profile pour recevoir un nouveau code.
            </p> 
          </> 
      }
    </>
  );
};

export default EmailVerification;