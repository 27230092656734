import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/layout'
// import { gsap } from 'gsap'
// import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import { navigate } from 'gatsby'
import { css } from "@emotion/react"; 
import BeatLoader from "react-spinners/BeatLoader"
import useQueryString from 'use-query-string';
import Seo from "../components/seo"
import EmailVerification from '../components/emailVerification';
import NewPassword from '../components/newPassword';


const Verify = () => {
  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState(null)
  const [emailVerification, setEmailVerification] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)

  // const userInit = {myUser: ""}
  // const { authUser }  = useContext(AuthContext) || userInit
  // const { user } = authUser || userInit

  useEffect(() => {
    if(query.oobCode){
      switch (query.mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          resetPassword(query.oobCode);
          break;
        // case 'recoverEmail':
        //   // Display email recovery handler and UI.
        //   handleRecoverEmail(auth, actionCode, lang);
        //   break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          verifyEmail(query.oobCode)
          break;
        default:
          navigate('/') 
      }   
    
    }else{
    navigate('/')  
    }
  },[])

  const [query] = useQueryString((typeof window !== "undefined") ? window.location : "")

  const firebase = useContext(FirebaseContext)
  const [verified, setVerified] = useState(false)

  function verifyEmail(code) {
    setEmailVerification(true)
    firebase.auth.applyActionCode(code).then(function(resp) {
      setVerified(true)
      setLoading(false)
    }).catch(function (error) {
      setLoading(false)
    })
  }

  function resetPassword(code){
    setPasswordReset(true)
    firebase.auth.verifyPasswordResetCode(code).then(function(email) {
      setEmail(email)
      setLoading(false)
    })
    .catch( error =>{
      setError(error)
      setLoading(false)
    })
  }

  const handlePatient = () => {
    navigate('/signin')
  }
  const handleDoctor = () => {
    navigate('/doctor/')
  }

  return (
    <Layout>
      <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti "  />
      <div className="sign-contain">
        <div className="sign-element mycard">
          {
            loading
            ?
            <div className="loader-container">
              <BeatLoader
                css={override}
                size={15}
                color={"#005EB8"}
                loading={loading}
              />
            </div>
            :
              <>
                {emailVerification && <EmailVerification verified={verified} />}
                {passwordReset && <NewPassword code={query.oobCode} email={email} />}
              </>
          }

        </div>
      </div>
    </Layout>
  )
}

export default Verify
